
import i18n from 'i18next';
//import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import getLocale from 'browser-locale';
import moment from 'moment';
//import { i18nextPlugin } from 'translation-check'
/* global __I18N_ENABLED__ */





const I18N_ENABLED = __I18N_ENABLED__ === 'true';

// Update moment with the right language, for date display
i18n.on('languageChanged', lng => {
  moment.locale(lng);
  console.log("moment locale :",moment.locale())
  if (self.localStorage) {
    self.localStorage.lang = lng;
  }
});

let defaultLanguage = getLocale();
if (self.localStorage && self.localStorage.lang) {
  defaultLanguage = self.localStorage.lang;
}

i18n
  .use(initReactI18next)
//  .use(Backend)
  .init({
    fallbackLng: 'en',
    lng: defaultLanguage,

    // To allow . in keys
    keySeparator: false,
    // To allow : in keys
    nsSeparator: '|',
    saveMissing: false,
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // If the translation is empty, return the key instead
    returnEmptyString: false,

    react: {
      useSuspense: true,
      withRef: true,
      // Needed for react < 16
      defaultTransParent: 'div'
    },

    resources: {
      en: {
        // Default namespace
        translation: require('../../locales/en/translation.json')
      },
      fr: {
        // Default namespace
        translation: require('../../locales/fr/translation.json')
      },
      es: {
        // Default namespace
        translation: require('../../locales/es/translation.json')
      },
      ar: {
        // Default namespace
        translation: require('../../locales/ar/translation.json')
      }
    }
  }, (err, t) => {
    if (err) return console.log('i18next: something went wrong loading', err);
    t('key'); // -> same as i18next.t
});

export default i18n;
