import React, { useCallback,useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import forEach from 'lodash/forEach';
import { fieldsAreValid } from '../../core/forms';
import debounce from 'lodash/debounce';
import without from 'lodash/without';
import moment from 'moment';
import size from 'lodash/size';
import get from 'lodash/get'
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import values from 'lodash/values';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import orderBy from 'lodash/orderBy';
import has from 'lodash/has';
import { Box, Flex, Text } from 'theme-ui';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import EditIcon from '@material-ui/icons/EditRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {utils as vizUtils } from '@diabee/viz';
const { getLocalizedCeiling } = vizUtils.datetime;
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import {
  MediumTitle,
  Body1,
} from '../../components/elements/FontStyles';
import Button from '../../components/elements/Button';
import Icon from '../../components/elements/Icon';
import Table from '../../components/elements/Table';
import Pagination from '../../components/elements/Pagination';
import TextInput from '../../components/elements/TextInput';
import BgRangeSummary from '../../components/clinic/BgRangeSummary';
import Pill from '../../components/elements/Pill';
import PopoverMenu from '../../components/elements/PopoverMenu';
import PopoverLabel from '../../components/elements/PopoverLabel';
import Popover from '../../components/elements/Popover';
import RadioGroup from '../../components/elements/RadioGroup';
import Checkbox from '../../components/elements/Checkbox';
import Chat from '../../components/chat';

import { useToasts } from '../../providers/ToastProvider';
import * as actions from '../../redux/actions';
import { useIsFirstRender , useLocalStorage } from '../../core/hooks';
import { borders, colors } from '../../themes/baseTheme';

export const Messages = (props) => {
  const { t, api, trackMetric, searchDebounceMs } = props;
  const isFirstRender = useIsFirstRender();
  const dispatch = useDispatch();
  const { set: setToast } = useToasts();  
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [patientChatFetchOption, setPatientChatFetchOption] = useState({});
  const [selectedChatPatient, setSelectedChatPatient] = useState(null);
  const [selectedChatClinic, setSelectedChatClinic] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();  
  const [chatFetchMinutesAgo, setChatFetchMinutesAgo] = useState(0);
  const allUsersMap = useSelector((state) => state.blip.allUsersMap);  
  const [messagesFetchOptions, setMessagesFetchOptions] = useState({});
  const selectedClinicId = useSelector((state) => state.blip.selectedClinicId);
  const loggedInUserId = useSelector((state) => state.blip.loggedInUserId);
  const rowsPerPage = 8;
  const messages = useSelector((state) => state.blip.chat);
  const clinics = useSelector((state) => state.blip.clinics);
  const clinic = get(clinics, selectedClinicId);
  const {
	fetchingPatientsForClinic,
	fetchingChat,
	fetchingClinicsForPatientFromClinician,
	sendingChat,
	sendingClinicChat,
	readingChat,
  } = useSelector((state) => state.blip.working);
  const defaultPatientChatFetchOptions = {
	  search: '',
      offset: 0,
	  sort:'+fullName',
	  limit: 8,
      sortType: 'cgm',
	  period:'1d',
  };
  
  const debounceSearch = useCallback(debounce(search => {
    setPatientChatFetchOption({
      ...patientChatFetchOption,
      offset: 0,
      sort: '+fullName',
      search,
	  limit: 8,
	  period:'1d',
    });
  }, searchDebounceMs), [patientChatFetchOption]);
  function handleAsyncResult(workingState, successMessage) {
    const { inProgress, completed, notification } = workingState;

    if (!isFirstRender && !inProgress) {
      if (completed) {
        setCancelDialog(false);

        setToast({
          message: successMessage,
          variant: 'success',
        });

        setSelectedPatient(null);
      }

      if (completed === false) {
        setToast({
          message: get(notification, 'message'),
          variant: 'danger',
        });
      }
    }
  }
  useEffect(() => {
    setLoading(fetchingChat.inProgress);
  }, [fetchingChat.inProgress]);
 
  // Fetchers
  useEffect(() => {
    if (
      loggedInUserId
      && clinic?.id
      && !fetchingChat.inProgress
    ) {
      dispatch(actions.async.fetchChat(api,selectedClinicId));
    }
  }, [loggedInUserId]);
  
  useEffect(() => {
    if (
      loggedInUserId
      && clinic?.id
      && !fetchingPatientsForClinic.inProgress
      && !isEmpty(patientChatFetchOption)
    ) {
      const fetchOptions = { ...patientChatFetchOption };
      if (isEmpty(fetchOptions.search)) delete fetchOptions.search;
      dispatch(actions.async.fetchPatientsForClinic(api, selectedClinicId, fetchOptions));
	  
	  //clinic.lastPatientFetchTime = moment.utc();
    }
  }, [loggedInUserId, patientChatFetchOption]);

  useEffect(() => {
	  if(clinic?.patientCount)
		setPageCount(Math.ceil(clinic.patientCount/ rowsPerPage));
  }, [clinic]);


  useEffect(() => {
    const { inProgress, completed, notification } = fetchingChat;
	
    if (!isFirstRender && !inProgress) {
      if (completed === false) {
        setToast({
          message: get(notification, 'message'),
          variant: 'danger',
        });
      }
    }
  }, [fetchingChat]);
  useEffect(() => {
    const chatFetchMoment = moment.utc(clinic?.lastChatFetchTime);

    // update patientFetchMinutesAgo upon new fetch
    setChatFetchMinutesAgo(moment.utc().diff(chatFetchMoment, 'minutes'));

    // update patientFetchMinutesAgo every minute thereafter
    const fetchTimeInterval = setInterval(() => {
      setChatFetchMinutesAgo(moment.utc().diff(chatFetchMoment, 'minutes'));
	  dispatch(actions.async.fetchChat(api,selectedClinicId))
    }, 1000 * 30);

    return () => clearInterval(fetchTimeInterval);
  }, [clinic?.lastChatFetchTime]);

  function handleRefreshChat() {
    trackMetric('Clinic - Refresh chat', { clinicId: selectedClinicId });
    dispatch(actions.async.fetchChat(api));
  }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
    setLoading(true);
    debounceSearch(event.target.value);
  }
  
  function handleClickChatPatient(patient){
	  setSelectedChatPatient(patient);
	  //console.log('selected :',selectedChatPatient);
	  //setSelectedChatClinic(null);
  }
  function handleClickChatClinicPatient(clinic){
	  setSelectedChatClinic(clinic);
	  //console.log("selected :", selectedChatClinic);
  }
  
  function readChat(messageId){
	  if(!readingChat.inProgress)
		dispatch(actions.async.readChat(api,messageId));
  }
  
  function handleClickSend(messageText){
	  if(selectedChatClinic){
		  dispatch(actions.async.sendClinicChat(api,selectedChatPatient.id,{
			  messagetext : messageText,
			  senderId: selectedClinicId,
			  senderType: 'clinic',
			  receiverId: selectedChatClinic.id,
			  receiverType: 'clinic',
			  patientId : selectedChatPatient.id,
			  timestamp: moment()
			  
		  }));
		  
	  }
	  else{		  
		  dispatch(actions.async.sendChat(api,{
			  messagetext : messageText,
			  senderId: selectedClinicId,
			  senderType: 'clinic',
			  receiverId: selectedChatPatient.id,
			  receiverType: 'patient',
			  timestamp: moment()
			  
		  }));
	  }
  }
  function handleClickSendClinic(messageText){
	  dispatch(actions.async.sendClinicChat(api,selectedChatPatient.id,{
		  messagetext : messageText,
		  senderId: selectedClinicId,
		  senderType: 'clinic',
		  receiverId: selectedChatClinic.id,
		  receiverType: 'clinic',
		  timestamp: moment()
		  
	  }));
  }

  function handleSortChange(newOrderBy) {
    const sort = patientChatFetchOption.sort || defaultPatientChatFetchOptions.sort;
    const currentOrder = sort[0];
    const currentOrderBy = sort.substring(1);
    const newOrder = newOrderBy === currentOrderBy && currentOrder === '+' ? '-' : '+';

    setPatientChatFetchOption({
      ...patientChatFetchOption,
      offset: 0,
      sort: `${newOrder}${newOrderBy}`,
    });

  }

  function handleClearSearch() {
    setSearchText('');
    setLoading(true);
    debounceSearch('');
  }

  function handlePageChange(event, page) {
    setPatientChatFetchOption({
      ...patientChatFetchOption,
	  limit: 8,
      offset: (page - 1) * rowsPerPage,
    });
	setPage(page);
  }
  function nbNotReadedChat(patient) {
	  var notReaded = filter(messages,function(message){
		  return ((message.sender == patient?.id && (!has(message,'readed') || message?.readed == false)) || message?.patientId == patient?.id);
	  });
	  return size(notReaded);
  }

  
  const renderClinicName = clinic => {
	  //console.log(clinic);
	  return (
	<Box className="chat-clinic-name" onClick={()=> {handleClickChatClinicPatient(clinic)}} sx={{ cursor: 'pointer' }}>
      <Text>{clinic.name}</Text>
    </Box>
	);
	
  };
  
  const columnsClinics = [
    {
      title: t('others team care'),
      field: 'Clinic-Name',
	  align: 'right',
      render: renderClinicName,
    }
  ];


 
  const renderName = patient => (
    <Box className="chat-patient-name" onClick={() => {handleClickChatPatient(patient);}} sx={{ cursor: 'pointer' }}>
      <Text onClick={() => {handleClickChatClinicPatient(null);}}>{nbNotReadedChat(patient) != 0 ? <b>{patient.fullName}</b> : patient.fullName}</Text>
		{patient?.otherClinics &&   (
			<Table
			  id="ChatListClinic"
			  label={t('Clinic List')}
			  columns={columnsClinics}
			  data={patient.otherClinics}
			  orderBy="name"
			  order="asc"
			  rowHover={false}
			  rowsPerPage={100}
			  page={1}
			  emptyText={null}
			  style={{fontSize: '12px'}}
			  containerStyles={{maxHeight: '560px', overflow: 'auto'}}
			/>
		)}
    </Box>
	

  );

  const renderDate = ({ time }) => (
    <Box className="chat-date">
      <Text>{moment(time).format(t('MM/DD/YYYY h:mm a'))}</Text>
    </Box>
  );

  /*const renderActions = member => (
    <Flex justifyContent="flex-end">
      <Button
        className="decline-invite"
        onClick={() => handleDecline(member)}
        processing={deletingPatientInvitation.inProgress && member.key === selectedInvitation.key}
        variant="secondary"
      >
        {t('Decline')}
      </Button>

      <Button
        className="accept-invite"
        onClick={() => {
          setSelectedInvitation(member);
          handleAccept(member);
        }}
        processing={acceptingPatientInvitation.inProgress && member.key === selectedInvitation.key}
        variant="primary"
        color="purpleMedium"
        bg="white"
        ml={2}
      >
        {t('Accept')}
      </Button>
    </Flex>
  );*/

  const columns = [
    {
      title: t('Name'),
      field: 'name',
      align: 'left',
      sortable: true,
      sortBy: 'fullName',
      searchable: true,
      render: renderName,
    }
  ];
  

  return (
    <>
      <Box sx={{ position: 'absolute', top: '8px', right: 4 }}>
        <TextInput
          themeProps={{
            width: 'auto',
            minWidth: '250px',
          }}
          fontSize="12px"
          placeholder={t('Search by Name')}
          icon={searchText ? CloseRoundedIcon : SearchIcon}
          iconLabel="search"
          onClickIcon={searchText ? handleClearSearch : null}
          name="search-invites"
          onChange={handleSearchChange}
          value={searchText}
          variant="condensed"
        />
      </Box>
	  <Flex style={{flexDirection: 'row'}}>
      <Box style={{flex: 1}}>
        <Table
          id="ChatList"
          label={t('Patient List')}
          columns={columns}
          data={values(clinic?.patients)}
          orderBy="fullName"
          order="asc"
          rowHover={false}
          rowsPerPage={rowsPerPage}
          page={page}
          emptyText={null}
          fontSize={1}
		  stickyHeader
          containerStyles={{maxHeight: '560px', overflow: 'auto'}}
        />

        {clinic?.patientCount && clinic.patientCount > rowsPerPage && (
          <Pagination
            px="5%"
            sx={{ position: 'absolute', bottom: '-50px' }}
            width="100%"
            id="clinic-invites-pagination"
            count={pageCount}
            page={page}
            disabled={pageCount < 2}
            onChange={handlePageChange}
            showFirstButton={false}
            showLastButton={false}
          />
        )}


      </Box>
	  <Box style={{flex: 5}}>
		<Chat
			chat={filter(messages?.messages,function(message){
				if(!selectedChatClinic)
					return message.sender == selectedChatPatient?.id || message.receiver == selectedChatPatient?.id;
				else
					return message.patientId == selectedChatPatient?.id && (message.sender == selectedChatClinic?.id || message.receiver == selectedChatClinic?.id)
				
			})}
			user={selectedClinicId}
			other={selectedChatPatient?.id}
			onClickSend={handleClickSend}
			loggedInUser={loggedInUserId}
			t={t}
			readChat={readChat}
		/>
	  </Box>
	  </Flex>
    </>
  );
};



Messages.propTypes = {
  api: PropTypes.object.isRequired,
  trackMetric: PropTypes.func.isRequired,
};

export default withTranslation()(Messages);
