import { createBrowserHistory } from 'history';
import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import qhistory from 'qhistory';
import { stringify, parse } from 'qs';
import assign from 'lodash/assign';
import throttle from 'lodash/throttle';

import blipState from '../reducers/initialState';
import reducers from '../reducers';
import { loadLocalState, saveLocalState } from './localStorage';

import createErrorLogger from '../utils/logErrorMiddleware';
import trackingMiddleware from '../utils/trackingMiddleware';
import createWorkerMiddleware from '../utils/workerMiddleware';
import { keycloak, keycloakMiddleware } from '../../keycloak';
import config from '../../config';

export const history = qhistory(createBrowserHistory(), stringify, parse);

const reducer = combineReducers({
  blip: reducers,
  router: connectRouter(history),
});

const worker = new Worker(new URL('./../../worker/index', import.meta.url));
const workerMiddleware = createWorkerMiddleware(worker);

function _createStore(api) {
  const middlewares = [
    workerMiddleware,
    thunkMiddleware,
    routerMiddleware(history),
    createErrorLogger(api),
    trackingMiddleware(api),
    keycloakMiddleware(api),
  ];
  const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

  const initialState = { blip: assign(blipState, loadLocalState()) };
  const store = createStoreWithMiddleware(reducer, initialState);

  store.subscribe(throttle(() => {
    saveLocalState({
      selectedClinicId: store.getState().blip?.selectedClinicId,
    });
  }, 1000));

  return store;
}

export default (api) => {
  return _createStore(api);
}